<template>
  <div>
    <svg-icon class="my-logo" iconClass="logo-check"></svg-icon>
    <!--
    <img class="my-img" src="../assets/images/logo/logo-text.png">
    -->
  </div>
</template>

<script>
export default {
  name: "test"
}
</script>

<style scoped>
.my-logo {
  width: 600px;
  height: 600px;
}
</style>